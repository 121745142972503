import { ModelApiKey } from '../types/models'
import { LocaleCode } from '../types/locales'
import { paths } from '~/lib/paths'

export default function () {
  const localePath = useLocalePath()
  const localeRoute = useLocaleRoute()
  const { locale } = useI18n()

  const localePathByType = (typename: string, slug?: string, category?: string, topic?: string) => {
    let path
    switch (typename) {
      case 'IndexRecord':
        path = localePath('/')
        break
      case 'PageRecord':
        path = localePath(`/${slug}`)
        break
      case 'DiscoverCategoryRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.DiscoverCategory].path}/${slug}`)
        break
      case 'ArticleRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.DiscoverCategory].path}/${category}/${slug}`)
        break
      case 'HelpTopicRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.HelpArticle].path}/${slug}`)
        break
      case 'HelpArticleRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.HelpArticle].path}/${topic}/${category}/${slug}`)
        break
      case 'CryptoAssetRecord':
        path = localePath(`/${paths[locale.value as LocaleCode][ModelApiKey.CryptoAsset].path}/${slug}`)
        break
      case 'GlossaryPage':
        path = localeRoute({ path: `/${paths[locale.value as LocaleCode].glossary.path}`, query: { term: slug } })
        break
      // TODO: Handle specific pages via CMS.
      case 'SearchPage':
        path = localePath({ path: `/${paths[locale.value as LocaleCode].search.path}` })
        break
      case 'TimeMachinePage':
        path = localePath({ path: `/${paths[locale.value as LocaleCode].tm.path}` })
        break
      case 'CompareAssetsPage':
        path = localePath({ path: `/${paths[locale.value as LocaleCode].compare.path}` })
        break
      default:
        return null
    }

    return path
  }

  return {
    localePathByType
  }
}
